import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
  Slide,
  Button,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Grow from "@mui/material/Grow";
import getAge from "get-age";
import moment from "moment";
import { useHistory } from "react-router-dom";

import en from "../lang/en/home.json";
import th from "../lang/th/home.json";

import { setLang, setDarkMode, setPage } from "../redux/action";
import { connect } from "react-redux";
import AOS from "aos";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         textAlign: 'center',
//         display: 'block'
//       },
//     large: {
//       width: theme.spacing(30),
//       height: theme.spacing(30),
//     }
//   }));
const Home = ({ setPage, dark, CurrentLang }) => {
  const His = useHistory();
  React.useEffect(() => {
    AOS.init({ duration: 800 });
    setPage(CurrentLang == "th" ? th.title : en.title);
  }, []);
  const [Lang, setLang] = useState(th);
  React.useEffect(() => {
    if (CurrentLang === "th") {
      setLang(th);
    } else {
      setLang(en);
    }
  }, [CurrentLang]);

  return (
    <div className="pl-3 pr-3">
      <Slide
        direction="right"
        in={true}
        timeout={localStorage.getItem("graphic") === null ? 600 : 0}
      >
        <Grid item xs={12}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={dark ? "text-light" : ""}
          >
            {Lang.title}
          </Typography>
        </Grid>
      </Slide>
      <hr className={dark ? "border-light" : ""} />
      <Card>
        <CardContent sx={{ backgroundColor: dark ? "#011345" : "" }}>
          <div className="container mt-4">
            <div className="row">
              <div className="col-lg-4 mr-2 col-12" data-aos="zoom-in">
                <Avatar
                  sx={{
                    width: { lg: 280, xs: "100%" },
                    height: { lg: 280, xs: "100%" },
                  }}
                  alt=""
                  src="https://ik.imagekit.io/cpxstorage/myport/prof"
                />
              </div>
              <div className="col-lg mt-5 mt-lg-0 col-12 d-flex align-items-center">
                <div className="d-block" data-aos="zoom-in-right">
                  <h3
                    className={
                      dark ? "text-dark-secondary pl-0 col-12" : "pl-0 col-12"
                    }
                  >
                    {Lang.desc[0].desc}
                  </h3>
                  <p className="text-muted" data-aos="fade-down">
                    {Lang.desc[4].desc}
                  </p>
                  <p className={dark ? "text-dark-secondary" : ""}>
                    {CurrentLang == "th" ? "อายุ " : "Age "}{" "}
                    {getAge(Lang.desc[1].desc)}{" "}
                    {CurrentLang == "th" ? " ปี" : " years old"}
                  </p>
                  <h6 className={dark ? "text-dark-secondary" : ""}>
                    <i>
                      {CurrentLang == "th"
                        ? "มีประสบการณ์การทำงานมากว่า " +
                          (new Date().getFullYear() - 2020) +
                          " ปี"
                        : "I have job experience up to " +
                          (new Date().getFullYear() - 2020) +
                          " years ago."}
                    </i>
                  </h6>
                  <p className={dark ? "text-dark-secondary" : ""}>
                    {Lang.desc[5].title}: {Lang.desc[5].desc}
                  </p>
                  <p className={dark ? "text-dark-secondary" : ""}>
                    {Lang.desc[6].title}: {Lang.desc[6].desc}
                  </p>
                  <div className="m-0 row" data-aos="zoom-in-right">
                    <Button
                      onClick={() => His.push("/job")}
                      variant="outlined"
                      className={
                        dark
                          ? "text-info border-info m-1 ml-0"
                          : "m-1 ml-0"
                      }
                    >
                      {CurrentLang == "th"
                        ? "ประวัติการทำงาน"
                        : "Job experience"}
                    </Button>
                    <Button
                      onClick={() => His.push("/skill")}
                      variant="outlined"
                      className={dark ? "text-info border-info m-1" : "m-1"}
                    >
                      {CurrentLang == "th"
                        ? "ความถนัดด้านภาษาโปรแกรมและเครื่องมือที่ใช้"
                        : "Coding and Computer skill"}
                    </Button>
                    <Button
                      onClick={() => His.push("/projects")}
                      variant="outlined"
                      className={dark ? "text-info border-info m-1" : "m-1"}
                    >
                      {CurrentLang == "th"
                        ? "ผลงานที่ผ่านมา"
                        : "All Previous Projects"}
                    </Button>
                    <Button
                      onClick={() => His.push("/contact")}
                      variant="outlined"
                      className={dark ? "text-info border-info m-1" : "m-1"}
                    >
                      {CurrentLang == "th" ? "ช่องทางการติดต่อ" : "Contact Me"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dark: state.dark,
  CurrentLang: state.CurrentLang,
  currentPage: state.currentPage,
  endpoint: state.endpoint,
});
const mapDispatchToProps = (dispatch) => ({
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
